import styled from '@emotion/styled/macro';
import { position, color } from 'styled-system';

export const Overlay = styled.div`
  ${position}
  ${color}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
