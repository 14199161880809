import React from 'react';
import { useInView } from 'react-intersection-observer';
import {
  Box,
  Flex,
  columnWidth,
  Heading,
  Text,
  Button,
  Container,
} from '@feast-it/pesto';

import Overlay from '../../components/Overlay';

const MAX_WIDTH = columnWidth(6);
const HEADING_MAX_WIDTH = columnWidth(4);

const InterestedInApplying = rawData => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const data = {
    title: rawData?.primary?.interested_in_title,
    description: rawData?.primary?.interested_in_description,
    buttonText: rawData?.primary?.button_text,
    buttonLink: rawData?.primary?.button_link?.url,
    image: rawData?.primary?.background_image.url,
  };

  return (
    <Box
      ref={ref}
      as="section"
      backgroundImage={inView && `url(${data.image})`}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundColor="black"
      color="white"
      textAlign="center"
      py={{ _: 9, m: 13 }}
      px={{ _: 'gutter._', s: 'gutter.s', m: 'gutter.m' }}
      position="relative"
    >
      <Overlay bg="black" opacity="0.5" />

      <Container maxWidth={MAX_WIDTH} position="relative">
        <Flex flexDirection="column" alignItems="center">
          <Box maxWidth={HEADING_MAX_WIDTH}>
            <Heading
              as="h2"
              variant={{ _: 'headingLargeMobile', m: 'headingLarge' }}
              textAlign="center"
            >
              {data.title}
            </Heading>
          </Box>

          <Text textAlign="center" variant="large" mt={6}>
            {data.description}
          </Text>

          <Button variant="light" href={data.buttonLink} mt={8}>
            {data.buttonText}
          </Button>
        </Flex>
      </Container>
    </Box>
  );
};

export default InterestedInApplying;
